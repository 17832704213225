@font-face {
  font-family: "Proxima Nova";
  src: url("https://assets.letsendorse.com/fonts/proxima_nova/Mark Simonson - Proxima Nova Alt Regular-webfont.eot");
  src: url("https://assets.letsendorse.com/fonts/proxima_nova/Mark Simonson - Proxima Nova Alt Regular-webfont.woff") format("woff"), url("https://assets.letsendorse.com/fonts/proxima_nova/Mark Simonson - Proxima Nova Alt Regular-webfont.otf") format("opentype"), url("https://assets.letsendorse.com/fonts/proxima_nova/Mark Simonson - Proxima Nova Alt Regular-webfont.svg") format("svg");
}

.proxima-nova {
  font-family: 'Proxima Nova', sans-serif !important;
}

* {
  /* outline: none !important; */
  font-family: 'Proxima Nova', 'Poppins', sans-serif;
}

.text-editor-container {
  min-width: 500px;
}

.module-container {
  padding: 20px;
}

.submit-buttom {
  padding: 50px;
  margin-bottom: 100px;
}

.save-button {
  top: 25px;
  right: 25px;
  width: 45px;
  height: 45px;
  border: none;
  font-size: 22px;
  position: fixed;
  border-radius: 50%;
  background: #78D245;
}

.save-button:hover,
.save-button:active {
  background: #58a12f !important;
}

.save-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 210, 69, .5);
}

button.btn.generate-pdf-button {
  top: 80px;
  right: 25px;
  width: 45px;
  height: 45px;
  border: none;
  cursor: pointer;
  font-size: 22px;
  position: fixed;
  border-radius: 50%;
  background: #EF5A20;
}

button.generate-pdf-button:hover,
button.generate-pdf-button:active {
  background: #cf4a17 !important;
}

button.btn.generate-pdf-button:disabled {
  cursor: not-allowed;
}

button.btn.generate-pdf-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 90, 32, .5);
}

.div-to-pdf {
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 70px;
  margin-bottom: 70px;
  border: 1px;
}

.desc-section {
  padding-top: 40px;
}

.block {
  page-break-inside: avoid !important;
}

.sub-block {
  padding-top: 50px;
}

page[size="A4"] {
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

@media print {

  body,
  page[size="A4"] {
    margin: 0;
    box-shadow: 0;
  }
}

.td-body-print {
  padding-top: 30;
  padding-bottom: 30;
}

@media print {
  .td-body-print {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.composite-checkbox-container {
  margin-left: 5%;
}

.table-center {
  margin-top: 40;
  padding-left: 40;
  padding-right: 40;
}

@media print {
  .table-center {
    margin-top: 40%;
  }
}

.scoring-input input[type=radio] {
  width: 12px;
  height: 12px;
}


.imp-notes {
  color: #ee0000;
  font-weight: 900;
  font-size: 10px;
  text-decoration: underline;
}

div>h1,
h3,
h4 {
  color: #ef5a20 !important;
}